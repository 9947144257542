
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { AppProps } from "next/app";
import App from "next/app";
import { useLoginByMoodle } from "modules/Auth/authHooks";
import "../styles/globals.css";
import localFont from "@next/font/local";
import { DefaultMeta } from "modules/Meta/DefaultMeta";
import { ApiContext } from "lib/apiFetcher";
import NextNProgress from "nextjs-progressbar";
import { ToastWrapper } from "components/Toast/ToastWrapper";
import { AlgoliaContext } from "lib/algolia";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSetUserAmplitudeWhenAuthChange, useSetUserGAWhenAuthChange, useSetUserGTMWhenAuthChange, } from "lib/amplitude";
import { setCookie, getCookie, getQueryStringParams, setParamsAsCookies, truncateDomain, } from "lib/helper";
import { useAuthStore } from "modules/Auth/authStore";
import { NextPageContext } from "next";
import { IsDesktopContext, isDeviceDesktop } from "lib/device";
import { IsBusinessContext, IsSubdomainContext, IsTenantContext, IsGateContext, isBusinessURL, isGateURL, isTenantURL, IsGateProvinceContext, isMalukuURL, } from "lib/host";
import { useGetSubdomainTenant } from "modules/Tenant/homeTenantApi";
import FloatingButton from "modules/Global/FloatingButton";
import { useSystemStore } from "components/PageHeader/systemStore";
import { Call, NoiseCancellationProvider, StreamCall, StreamVideo, StreamVideoClient, } from "@stream-io/video-react-sdk";
import { NoiseCancellation, type INoiseCancellation, } from "@stream-io/audio-filters-web";
import ReactGA from "react-ga4";
import { GoogleAnalytics, GoogleTagManager } from "@next/third-parties/google";
import { useAvatarStore } from "components/InteractiveAvatar/avatarStore";
const CircularFont = localFont({
    src: [
        { path: "../fonts/circular-light.woff2", weight: "300" },
        { path: "../fonts/circular-medium.woff2", weight: "500" },
        { path: "../fonts/circular-black.woff2", weight: "700" },
    ],
});
const callId = "gok-" + Math.random().toString(16).substring(2);
const user_id = "gok-user";
const user = { id: user_id };
const apiKey = "mmhfdzb5evj2";
const tokenProvider = async () => {
    const { token } = await fetch("https://pronto.getstream.io/api/auth/create-token?" +
        new URLSearchParams({
            api_key: apiKey,
            user_id: user_id,
        })).then((res) => res.json());
    return token as string;
};
const MyApp = ({ Component, pageProps, router }: AppProps) => {
    useSetUserGTMWhenAuthChange();
    useSetUserGAWhenAuthChange();
    useSetUserAmplitudeWhenAuthChange();
    useLoginByMoodle();
    const [theme, setTheme, setAllTheme, allThemes] = useSystemStore((state) => [
        state.theme,
        state.setTheme,
        state.setAllTheme,
        state.allThemes,
    ]);
    const [isSubdomainTenant, setSubdomainTenant] = useState(false);
    const { loginState, currentUser } = useAuthStore((state) => ({
        loginState: state.loginState,
        currentUser: state.currentUser,
    }));
    let referral = typeof router.query.referral == "string"
        ? router.query.referral
        : getCookie("ref_code_gk") &&
            getCookie("ref_code_gk") !== "null" &&
            getCookie("ref_code_gk");
    /// Add / Remove dark class in html
    useEffect(() => {
        const script = document.createElement("script");
        if (localStorage.getItem("theme") === "dark") {
            script.innerHTML = "document.documentElement.classList.add('dark');";
        }
        else {
            script.innerHTML = "document.documentElement.classList.remove('dark');";
        }
        document.body.appendChild(script);
        // Cleanup function to remove the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, [theme]);
    useEffect(() => {
        // Load Theme from local storage
        const savedTheme = localStorage.getItem("theme");
        if (savedTheme) {
            // Set initial color scheme
            // @ts-ignore
            setTheme(savedTheme);
            setAllTheme(allThemes.map((x) => x.id === savedTheme ? { ...x, active: true } : { ...x, active: false }));
        }
        else {
            let defaultTheme = "light";
            // Save Theme to local storage
            localStorage.setItem("theme", defaultTheme);
            // Change Theme in local variable
            setTheme(defaultTheme);
            setAllTheme(allThemes.map((x) => x.id === defaultTheme
                ? { ...x, active: true }
                : { ...x, active: false }));
        }
    }, []);
    const queries = router.query && router.query.hasOwnProperty("utm_source")
        ? Object.fromEntries(Object.entries(router.query).filter(([key, value]) => key.includes("utm")))
        : {};
    const uri = router.query &&
        ["/login", "/register"].includes(router.pathname) &&
        router.query.url
        ? router.query.url
        : null;
    // ========== Referral Code ========== //
    useEffect(() => {
        // any spesific cookie and different with current referral
        const isExistCookie = ["null", referral].every((a) => a !== getCookie("ref_code_gk"));
        // no have spesific cookie and have referral query
        const isUseRefQuery = !getCookie("ref_code_gk") && referral;
        if (loginState == "NOT_LOGIN" && referral) {
            if (isExistCookie || isUseRefQuery) {
                setCookie("ref_code_gk", referral, 7);
            }
        }
    });
    // ========== Referral Code ========== //
    // ========== UTM ========== //
    useEffect(() => {
        try {
            let queriesURI = uri
                ? //@ts-ignore
                    getQueryStringParams(decodeURIComponent(uri).split("?")[1])
                : {};
            setParamsAsCookies(queries, queriesURI);
        }
        catch (err) {
            console.log(err);
        }
    });
    // ========== UTM ========== //
    //@ts-ignore
    const getSubdomainAPI = useGetSubdomainTenant();
    const subdomain = pageProps?.subdomain;
    const referralCode = getCookie("ref_code_gk") && getCookie("ref_code_gk") !== "null"
        ? getCookie("ref_code_gk")
        : typeof window !== "undefined"
            ? new URLSearchParams(window.location.search).get("referral")
            : "";
    useEffect(() => {
        if (subdomain &&
            !["tenant", "gokampus"].includes(subdomain)
        // ||
        // referralCode == "trenggalekkab"
        ) {
            getSubdomainAPI.doFetch({
                slug: window.location.hostname === "e-learning.bluebirdgroup.com"
                    ? "bluebird"
                    : // : referralCode == "trenggalekkab"
                        // ? referralCode
                        subdomain,
            }, {
                onSuccess(data) {
                    //@ts-ignore
                    setSubdomainTenant(data?.status);
                },
                onError(err) {
                    router.push("/404");
                    setSubdomainTenant(false);
                },
            });
        }
        else {
            setSubdomainTenant(false);
        }
    }, [
        subdomain,
        // , referralCode
    ]);
    // ========== fetch api to check if subdomain registered ========== //
    const isHideFeedback = router.pathname === "/course/[courseSlug]" && !pageProps?.isDesktop;
    // ========== Service Worker ========== //
    useEffect(() => {
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker
                .register("/service-worker.js")
                .then((registration) => console.log("scope is: ", registration.scope))
                .catch((err) => console.log("SW registration failed: ", err));
        }
    }, []);
    // ========== Service Worker ========== //
    const { setIsScrolled } = useAvatarStore();
    const [previousPath, setPreviousPath] = useState("");
    useEffect(() => {
        if (!previousPath) {
            if (router.pathname === "/") {
                setIsScrolled(false);
            }
            else {
                setIsScrolled(true);
            }
        }
        else if (previousPath.includes("login") ||
            previousPath.includes("register")) {
            setIsScrolled(false);
        }
        else {
            if (router.pathname === "/" && previousPath !== "/") {
                setIsScrolled(true);
            }
            else if (router.pathname !== "/") {
                setIsScrolled(true);
            }
        }
        setPreviousPath(router.pathname);
    }, [router.pathname, previousPath]);
    const [client, setClient] = useState<StreamVideoClient>();
    const [call, setCall] = useState<Call>();
    const [noiseCancellation, setNoiseCancellation] = useState<INoiseCancellation>();
    const loader = useRef<Promise<void>>();
    useEffect(() => {
        const myClient = new StreamVideoClient({ apiKey, user, tokenProvider });
        setClient(myClient);
        return () => {
            myClient.disconnectUser();
            setClient(undefined);
        };
    }, []);
    useEffect(() => {
        if (!client)
            return;
        const myCall = client.call("default", callId);
        myCall.join({ create: true }).catch((err) => {
            console.error(`Failed to join the call`, err);
        });
        setCall(myCall);
        return () => {
            setCall(undefined);
            myCall.leave().catch((err) => {
                console.error(`Failed to leave the call`, err);
            });
        };
    }, [client]);
    useEffect(() => {
        const load = (loader.current || Promise.resolve())
            .then(() => import("@stream-io/audio-filters-web"))
            .then(({ NoiseCancellation }) => {
            setNoiseCancellation(new NoiseCancellation());
        });
        return () => {
            loader.current = load.then(() => setNoiseCancellation(undefined));
        };
    }, []);
    if (!client || !call || !noiseCancellation)
        return null;
    // const gaId = CONST.GA_ID;
    // const gaId = "G-22FPWZ0V06";
    const gaId = "G-XRDLB20DYP";
    // const gtmId = CONST.GTM_ID;
    const gtmId = "GTM-MBKC7GJR";
    ReactGA.initialize([
        {
            trackingId: gaId,
            gaOptions: {
                userId: currentUser?.id,
            },
        },
    ]);
    return (<>
      <NextNProgress color={theme == "dark" ? "#A184DD" : "#551FC1"} options={{
            showSpinner: false,
        }} transformCSS={(css) => {
            return <style>{css.replaceAll("\n", "")}</style>;
        }}/>
      <div className={[
            CircularFont.className,
            "text-purple-80 font-medium relative bg-white",
        ].join(" ")}>
        <IsDesktopContext.Provider value={pageProps?.isDesktop}>
          <IsTenantContext.Provider value={pageProps?.isTenant}>
            <IsBusinessContext.Provider value={pageProps?.isBusiness}>
              <IsGateContext.Provider value={pageProps?.isGate}>
                <IsGateProvinceContext.Provider value={pageProps?.isGateProvince}>
                  <IsSubdomainContext.Provider value={isSubdomainTenant}>
                    <ApiContext.Provider value={{ __apiData: pageProps?.__apiData || {} }}>
                      <AlgoliaContext.Provider value={{
            __algoliaData: pageProps?.__algoliaData || {},
        }}>
                        {/* <StreamVideo client={client}>
          <StreamCall call={call}>
            <NoiseCancellationProvider
              noiseCancellation={noiseCancellation}
            >
            </NoiseCancellationProvider>
          </StreamCall>
        </StreamVideo> */}
                        <DefaultMeta withDefault={true}/>
                        {/* <GoogleTagManager gtmId={gtmId} />
              <GoogleAnalytics gaId={gaId} /> */}
                        <Component {...pageProps}/>
                        <ToastWrapper />
                        {/* {!(["/login", "/register", "/404", "/500"].includes(router.pathname) || isHideFeedback) && <FloatingButton />} */}
                      </AlgoliaContext.Provider>
                    </ApiContext.Provider>
                  </IsSubdomainContext.Provider>
                </IsGateProvinceContext.Provider>
              </IsGateContext.Provider>
            </IsBusinessContext.Provider>
          </IsTenantContext.Provider>
        </IsDesktopContext.Provider>
      </div>
    </>);
};
MyApp.getInitialProps = async (appContext) => {
    let isDesktop = false;
    let isTenant = false;
    let isBusiness = false;
    let isGate = false;
    let isGateProvince = false;
    let subdomain: string;
    const appProps = await App.getInitialProps(appContext);
    try {
        if (appContext.ctx.req) {
            subdomain = truncateDomain(appContext.ctx.req?.headers?.["host"]);
            isDesktop = isDeviceDesktop(appContext.ctx.req?.headers?.["user-agent"]);
            isTenant = isTenantURL(appContext.ctx.req?.headers?.["host"]);
            isBusiness = isBusinessURL(appContext.ctx.req?.headers?.["host"]);
            isGate = isGateURL(appContext.ctx.req?.headers?.["host"]);
            isGateProvince = isMalukuURL(appContext.ctx.req?.headers?.["host"]);
        }
        else {
            subdomain = truncateDomain(window.location.hostname);
            isDesktop = isDeviceDesktop(window.navigator.userAgent);
            isTenant = isTenantURL(window.location.hostname);
            isBusiness = isBusinessURL(window.location.hostname);
            isGate = isGateURL(window.location.hostname);
            isGateProvince = isMalukuURL(window.location.hostname);
        }
    }
    catch { }
    return {
        pageProps: {
            ...appProps,
            isDesktop,
            isTenant,
            isBusiness,
            isGate,
            subdomain,
            isGateProvince,
        },
    };
};
const __Next_Translate__Page__19491465125__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__19491465125__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  