import { StartAvatarResponse } from "@heygen/streaming-avatar";
import { SetStateAction } from "react";
import createStore from "zustand";

export type AvatarStore = {
  // state
  streamAvatar: MediaStream;
  isAvatarFullScreen: boolean;
  scrollTriggeredByTop: boolean;
  remainingDuration: number;
  showSidebar: boolean;
  showSummarySidebar: boolean;
  showQuizSidebar: boolean;
  dataAvatarGlobal: StartAvatarResponse;
  isScrolled: boolean;
  isDurationFetched: boolean;

  // actions
  setStreamAvatar: (stream: SetStateAction<MediaStream>) => void;
  setIsAvatarFullScreen: (isFullScreen: SetStateAction<boolean>) => void;
  setScrollTriggeredByTop: (isTriggered: SetStateAction<boolean>) => void;
  setRemainingDuration: (duration: SetStateAction<number>) => void;
  setShowSidebar: (show: SetStateAction<boolean>) => void;
  setShowSummarySidebar: (show: SetStateAction<boolean>) => void;
  setShowQuizSidebar: (show: SetStateAction<boolean>) => void;
  setDataAvatarGlobal: (data: SetStateAction<StartAvatarResponse>) => void;
  setIsScrolled: (isScrolled: SetStateAction<boolean>) => void;
  setIsDurationFetched: (isFetched: SetStateAction<boolean>) => void;
};

export const useAvatarStore = createStore<AvatarStore>((set) => ({
  // state
  streamAvatar: undefined,
  isAvatarFullScreen: true,
  scrollTriggeredByTop: true,
  remainingDuration: 0,
  showSidebar: false,
  showSummarySidebar: false,
  showQuizSidebar: false,
  dataAvatarGlobal: undefined,
  isScrolled: false,
  isDurationFetched: false,

  // actions
  setStreamAvatar: (stream) =>
    set((state) => ({
      streamAvatar:
        typeof stream === "function" ? stream(state.streamAvatar) : stream,
    })),
  setIsAvatarFullScreen: (isFullScreen) =>
    set((state) => ({
      isAvatarFullScreen:
        typeof isFullScreen === "function"
          ? isFullScreen(state.isAvatarFullScreen)
          : isFullScreen,
    })),
  setScrollTriggeredByTop: (isTriggered) =>
    set((state) => ({
      scrollTriggeredByTop:
        typeof isTriggered === "function"
          ? isTriggered(state.scrollTriggeredByTop)
          : isTriggered,
    })),
  setRemainingDuration: (duration) =>
    set((state) => ({
      remainingDuration:
        typeof duration === "function"
          ? duration(state.remainingDuration)
          : duration,
    })),
  setShowSidebar: (show) =>
    set((state) => ({
      showSidebar: typeof show === "function" ? show(state.showSidebar) : show,
    })),
  setShowSummarySidebar: (show) =>
    set((state) => ({
      showSummarySidebar:
        typeof show === "function" ? show(state.showSummarySidebar) : show,
    })),
  setShowQuizSidebar: (show) =>
    set((state) => ({
      showQuizSidebar:
        typeof show === "function" ? show(state.showQuizSidebar) : show,
    })),
  setDataAvatarGlobal: (data) =>
    set((state) => ({
      dataAvatarGlobal:
        typeof data === "function" ? data(state.dataAvatarGlobal) : data,
    })),
  setIsScrolled: (isScrolled) =>
    set((state) => ({
      isScrolled:
        typeof isScrolled === "function"
          ? isScrolled(state.isScrolled)
          : isScrolled,
    })),
  setIsDurationFetched: (isFetched) =>
    set((state) => ({
      isDurationFetched:
        typeof isFetched === "function"
          ? isFetched(state.isDurationFetched)
          : isFetched,
    })),
}));
